.image-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
}

.image-grid img {
    width: 100%; /* Ensures the image fills its grid cell */
    height: 100%; /* Ensures the image fills its grid cell */
    object-fit: cover; /* Ensures the image covers the entire grid cell */
}
