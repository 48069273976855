#hero {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  border-bottom: 0px;
  background: $white-color;
  font-weight: 400;
  color: $dark-blue-text;
  padding: 0rem 5.6rem;
  margin-bottom: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  // Set position sticky so the jumbotron stays fixed while you scroll.
  // position: sticky;

  // If you want to set a background image on the hero section, uncomment these with your custom image

  background: url("/src/assets/bgcomp.jpg");
  background-position: center;
  background-size: cover; 

  @include respond(phone) {
    padding: 0rem $default-font-size;
  }

  #unique-heading {
    background-color: transparent; /* Transparent background */
    padding: 10px; /* Adding padding for better visibility */
    border-radius: 5px; /* Adding border radius for a rounded look */
    text-align: center; /* Centering text */
    font-family: Arial, sans-serif; /* Adding a fallback font */
    font-size: 40px; /* Adjust the font size to your preference */
}


.blue {
    color: #ffffff; /* Blue color */
}

.orange {
    color: #ffffff; /* Orange color */
}

.off-white {
    color: #f0f0f0; /* Off-white color */
}

#unique-heading span {
    margin-right: 5px; /* Adding space between words */
}

  
.left-text {
  position: absolute;
  left: 50%; /* Position 50% from the left */
  top: 50%; /* Position 50% from the top */
  transform: translate(-50%, -50%); /* Center horizontally and vertically */
  max-width: 35%;
  font-weight: bold; /* Make the text bold */

}

@media screen and (max-width: 768px) {
  .left-text {
      left: 35%;
      top: 20%; /* Position 50% from the top */
      transform: translate(-50%, -50%); /* Center horizontally and vertically */
      max-width: 80%;
      font-size: 14px;
      max-height: 20%;
      font-weight: bold; /* Make the text bold */

  }
}

  .hero-title {
    // background: url("/src/assets/unnamed-1.png");
    font-size: 5rem;
    font-weight: 700;
    margin-bottom: 3.2rem;
    text-align: left;

    @include respond(tab-land) {
      font-size: 4rem;
    }
    @include respond(tab-port) {
      font-size: 3.6rem;
      text-align: center;
    }
    @include respond(phone) {
      font-size: 3.5rem;
      line-height: 1.5;
    }
    @include respond(phone-xs) {
      font-size: 2.8rem;
    }
  }

  .hero-cta {
    display: flex;

    @include respond(tab-port) {
      justify-content: center;
    }

    & a {
      font-size: 2.4rem;

      @include respond(phone) {
        font-size: 2rem;
      }
    }
  }
}
